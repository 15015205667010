<template>
    <div class="xw_page_container">
        <div class="member_header">
            <div class="member_title_name " v-if="$store.state.windowWidth > 768">
                {{ $t('備用網址') }}
            </div>

            <div class="bg_header" v-else>
                <div class="header_content">
                    <button @click="goBack" type="button" class="btn_back"></button>
                    <span>{{ $t('備用網址') }}</span>
                </div>
            </div>
        </div>

        <div class="member_content">
            <div class="inpage_in">
                <div class="urlTop">
                    <span class="urlTop_prompt">{{ $t('※ 溫馨提示：反應時間越小，網站速度越快。') }}</span>
                    <button @click="testResponseTimes" type="button" class="btn_refresh"> {{ $t('刷新') }}</button>
                </div>

                <div class="urlIn backupUrlList">
                    <div class="urlList" v-for="(item, index) in websiteList" :key="index">
                        <div class="urlList_L">
                            <img v-if="results[index]" src="@/assets/images/xw/icon_loadingOk.png" alt="" class="bank-icon">
                            <img v-else src="@/assets/images/xw/icon_loading.png" alt="" class="bank-icon">
                            <span> <span class="txt-red">{{ results[index]?.time || '--' }}</span> 秒</span>
                        </div>
                        <div class="fg_line"></div>
                        <div>{{ $t('備用網址') }}{{ index + 1 }}</div>
                        <div>
                            <button @click="openPage(item)" type="button" class="btn_enterWeb">{{ $t('進入網站') }}</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <xwTabbar v-if="$store.state.windowWidth <= 768" />

    </div>
</template>

<script>
import xwTabbar from "@/components/xwTabbar.vue";
import {
    rule_index_api
} from "@/api/index";
export default {
    components: {
        xwTabbar,
    },
    data() {
        return {
            websiteList: [
                'https://fs45fg.live',
                'https://eerunn.online',
                'https://htoi.online'
            ],
            results: []
        }
    },
    mounted() {
        this.testResponseTimes()
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        openPage(url){
            window.open(url)
        },
        async measureResponseTime(url) {
            const start = performance.now();
            try {
                await fetch(url, { mode: 'no-cors' }); // 使用 no-cors 模式避免跨域问题
                const end = performance.now();
                const timeInSeconds = ((end - start) / 1000).toFixed(1);
                return {
                    url: url,
                    time: timeInSeconds
                };
            } catch (error) {
                return {
                    url: url,
                    time: '请求失败'
                };
            }
        },
        async testResponseTimes() {
            this.results = []; 
            const promises = this.websiteList.map(domain => this.measureResponseTime(domain));
            promises.forEach(async (promise) => {
                const result = await promise;
                this.results.push(result);
            });
        }
    }
}
</script>

<style lang="less" scoped>
.xw_page_container {
    width: 100vw;
    min-height: var(--vh);
    background-color: #ebebeb;
}

.member_header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #3a9b7c;

    @media (max-width:768px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 40px;
        background-color: #3f9eff;
    }
}

.member_title_name {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-left: 34px;
}

.search_item {
    margin-left: 0;
    height: 0;
    position: absolute;
    top: 24px;
    left: 179px;
    width: max-content;
}

.member_tab_list {
    list-style: none;
    display: flex;
    align-items: center;
    user-select: none;
}

.member_tab_item {
    min-width: auto;
    padding: 0 17px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    width: max-content;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}

.member_tab_item.active {
    background-color: #ebebeb !important;
    color: #3a9b7c;
}

.bg_header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
}

.header_content {
    position: relative;
    color: #fff;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-weight: bold;
}

.btn_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 40px;
    display: block;
    background: url('../../assets/images/xw/btn_back.svg') no-repeat center;
    background-size: auto 40%;
}

.member_content {
    width: 100%;
    margin: 0 auto;
    padding: 15px;

    @media (max-width:768px) {
        padding: 40px 0;
    }
}

.main_content {
    width: 92%;
    max-width: 1000px;
    min-height: 300px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
    padding: 5%;
    font-size: .8em;
    line-height: 1.7em;
    word-break: break-all;
    text-align: justify;
    color: #555;

    @media (max-width:768px) {
        width: 100%;
    }
}

.inpage_in {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 15px;
    box-sizing: border-box;
}

.urlTop {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    box-sizing: border-box;
    margin: 5px auto 20px auto;
}

.urlTop_prompt {
    color: #30679f;
    line-height: 24px;
    font-size: 14px;
}

.btn_refresh {
    font-family: 微軟正黑體, Arial;
    border: 0px;
    outline: none;
    float: right;
    color: #fff;
    background: #ff8a00 url('../../assets/images/xw/icon_refresh.png') no-repeat 13px 50%;
    background-size: 20px auto;
    height: 35px;
    font-size: 14px;
    border-radius: 3px;
    width: 100px;
    cursor: pointer;
    margin: 0;
    padding: 0 0 0 28px;
}

.urlList {
    background-color: #fff;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 28px;
    padding-right: 10px;
    margin-bottom: 20px;
}

.fg_line {
    width: 1px;
    height: 28px;
    background-color: #eee;
}

.urlList_L {
    display: flex;
    align-items: center;
}

.txt-red {
    color: #f00;
    margin: 0 5px 0 10px;
    width: 45px;
    display: inline-block;
    text-align: right;
}

.btn_enterWeb{
    background-color: #32abff;
    color: #fff;
    padding: 4px 10px;
    border-radius: 6px;
}
</style>